import { Slide, Snackbar } from "@mui/material";
import Icon from "../UI/Icon";

import {
	Root,
	classes,
} from "../../assets/styles/components/NewVersionAlert.js";

const NewVersionAlert = ({ showUpdatedMessage, setShowUpdatedMessage }) => {
	return (
		<Snackbar
			style={{
				zIndex: !showUpdatedMessage ? -1 : 2147483005,
			}}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			open={showUpdatedMessage}
			autoHideDuration={8000}
			onClose={() => setShowUpdatedMessage(false)}
			TransitionComponent={(props) => <Slide {...props} direction="right" />}
		>
			<Root className={classes.newVersionBanner}>
				<Icon name="refresh" className={classes.icon} />
				<div>
					<p className={classes.bannerTitle}>Application Updated!</p>
					<p className={classes.bannerDescription}>
						Your app just updated to the latest milesCX version!
					</p>
				</div>
			</Root>
		</Snackbar>
	);
};

export default NewVersionAlert;
